<template>
  <div class="grid">
    <CrudTable
      ref="shipment"
      :entities="entities"
      :meta="metaTable"
      @tableAction="onTableAction"
      @itemAction="onItemAction"
      @cell-edit-complete="onCellEditComplete"
    />
  </div>
  <Dialog v-model:visible="showPackageImages" class="images">
    <template #header>
      <h3>{{ $t("package.images") }}</h3>
    </template>
    <Galleria
      v-model:visible="showPackageImages"
      :value="images"
      :responsiveOptions="responsiveOptions"
      :numVisible="5"
      :circular="true"
      containerStyle="max-width: 640px"
      :showItemNavigators="true"
      :showThumbnails="false"
    >
      <template #item="slotProps">
        <img
          :src="slotProps.item.imageSrc"
          :alt="slotProps.item.alt"
          style="width: 100%; display: block"
        />
      </template>

      <template #thumbnail="slotProps">
        <img
          :src="slotProps.item.thumbnailSrc"
          :alt="slotProps.item.alt"
          style="display: block"
        />
      </template>
    </Galleria>
    <!--
    <template #footer>
      <Button :label="$t('button.save')" icon="pi pi-save" @click="onSaveMessage" autofocus />
    </template>
    -->
  </Dialog>
</template>
<script>
import CrudTable from "@/pages/common/CrudTable";
import PackageService from "@/service/PackageService";
import ShipmentService from "@/service/ShipmentService";
import ReceiptService from "@/service/ReceiptService";
import AuthService from "@/service/AuthService";
import ImageService from "@/service/ImageService";
import adjustment from "@/utils/adjustment";
import formatter from "@/utils/formatter";
import { converter } from "@/utils/common";
import { Consts } from "@/utils/consts";
import preview from "@/mixins/preview";
import { writeFile, utils } from "xlsx";
import confirm from "@/mixins/shipment_confirm";
export default {
  components: { CrudTable },
  mixins: [preview, confirm],
  data() {
    let t = this.$t;
    const format_process_status = function (status) {
      return formatter.formatPackageProcessStatus(t, status);
    };
    const metaTable = {
      entityName: "package",
      expander: false,
      searchable: true,
      rowEditor: true,
      editMode: "cell",
      sortMode: "multiple",
      multiSortMeta: [{ field: "createdDate", order: -1 }],
      actionsHeaderStyle: "width:5%; min-width:5rem;",
      header: {
        title: "",
      },
      paginator: {
        enable: true,
        rows: Consts.DEFAULT_PAGE_ROWS,
        rowsPerPageOptions: Consts.ROW_PER_PAGE_OPTIONS,
        currentPageReportTemplate:
          "Showing {first} to {last} of {totalRecords} packages",
      },
      filter: {
        filterDisplay: "menu",
        globalFilterFields: ["shipmentCode", "awbCode"],
      },
      columns: [
        {
          name: "createdDate",
          label: "package.createdDate",
          headerStyle: "width:8%; min-width:8rem;",
          filter: true,
          dataType: "date",
          formatter: formatter.formatTime,
        },
        {
          name: "packageCode",
          headerStyle: "width:10%; min-width:12rem;",
          filter: true,
          editor: {
            enable: false,
          },
        },
        // {
        //   name: "agentCode",
        //   label:"package.agentCode",
        //   headerStyle: "width:5%; min-width:5rem;",
        //   filter: true,
        //   condition:"user.orgType=='owner'"
        // },
        {
          name: "weightLbs",
          label: "shipment.weight",
          headerStyle: "width:8%; min-width:8rem;",
          filter: true,
          dataType: "numeric",
          formatter: converter.formatLbsWeight,
        },
        {
          name: "actualWeightKg",
          label: "shipment.actual_weight_kg",
          headerStyle: "width:15%; min-width:12rem;",
          filter: true,
          dataType: "numeric",
          editor: {
            enable: true,
            style: "max-width: 20rem",
          },
        },
        {
          name: "processStatus",
          headerStyle: "width:10%; min-width:10rem;",
          filter: true,
          formatter: format_process_status,
        },
        {
          name: "description",
          headerStyle: "width:25%; min-width:20rem;",
          filter: true,
        },
        {
          name: "storeNote",
          headerStyle: "width:25%; min-width:20rem;",
          filter: true,
          editor: {
            enable: true,
          },
        },
      ],
      tableActions: [
        {
          name: "filterclear",
          icon: "pi pi-filter-slash",
          style: "p-button-outlined mr-2",
        },
        {
          name: "export",
          icon: "pi pi-file-export",
          style: "p-button mr-2",
          condition: "shipment.id",
        },
      ],
      itemActions: [
        // {
        //   name: "edit",
        //   icon: "pi pi-pencil",
        //   style: "p-button-rounded mr-2",
        //   condition: "mode != 'edit'"
        // },
        // {
        //   name: "delete",
        //   icon: "pi pi-trash",
        //   style: "p-button-rounded p-button-warning mr-2",
        //   condition: "mode != 'edit'"
        // },
        {
          name: "print",
          icon: "pi pi-print",
          style: "p-button-rounded mr-2",
        },
      ],
      metaSubTable: {
        entityName: "packageItem",
        dataField: "packageItems",
        paginator: {
          enable: false,
        },
        columns: [
          {
            name: "name",
            headerStyle: "width:30%; min-width:10rem;",
            filter: true,
            editor: {
              enable: false,
            },
          },
          {
            name: "quantity",
            headerStyle: "width:20%; min-width:10rem;",
            filter: true,
            editor: {
              enable: false,
            },
          },
          {
            name: "description",
            headerStyle: "width:50%; min-width:10rem;",
            filter: true,
            editor: {
              enable: false,
            },
          },
        ],
      },
    };
    return {
      entities: [],
      shipment: {},
      selectedEntity: null,
      currentUser: null,
      metaTable,
      showPackageImages: false,
      images: [],
    };
  },
  created() {
    this.service = PackageService;
  },
  mounted() {
    this.currentUser = AuthService.currentUser;
    ShipmentService.get(this.$route.params.id).then((response) => {
      this.shipment = adjustment.adjustShipment(response);
      let info = this.shipment.shipmentCode;
      if (this.shipment.awbCode) {
        info += `- ${this.shipment.awbCode}`;
      }
      if (this.shipment.departure) {
        let departure = this.formatDate(this.shipment.departure);
        info += `- ${departure}`;
      }
      this.metaTable.header.title = info;
      if (Array.isArray(this.shipment.packages)) {
        this.entities = this.shipment.packages; //.map(id=>mapPackages[id]);
      } else {
        this.entities = [];
      }
      this.entities.forEach((pkg) => {
        pkg.description = pkg.itemDtos
          ?.map((item) => item.quantity + " " + item.name)
          .join(", ");
      });
    });
  },
  methods: {
    formatDate(jsDate) {
      return formatter.formatDate(jsDate);
    },
    tokg(lbs) {
      return converter.tokg(lbs);
    },
    tolbs(kg) {
      return converter.tolbs(kg);
    },
    storeStatus(status) {
      return isNaN(status) ? "" : this.$t("package.store_status_" + status);
    },
    onTableAction(sAction) {
      if (sAction == "export") {
        this.exportManifest();
      } else if (sAction == "send") {
        this.onSendShipment(this.shipment);
      }
    },
    onItemAction(sAction, entity, index) {
      if (sAction == "edit") {
        this.$router.push({
          name: "agent_package_edit",
          params: { receiptId: entity.receiptId },
        });
      } else if (sAction == "print") {
        ReceiptService.findByCode(entity.receiptCode).then((data) => {
          if (Array.isArray(data) && data.length == 1) {
            const receiptId = data[0].id;
            //const routeData = this.$router.resolve({ name: 'print_preview', params: {resource: "shipment", id: receiptId, packageId: entity.id} });
            const routeData = this.$router.resolve({
              name: "print_preview",
              params: {
                resource: "receipt",
                id: receiptId,
                packageId: entity.id,
              },
            });
            window.open(routeData.href);
          }
        });
      } else if (sAction == "qrcode") {
        console.log(entity);
      } else if (sAction == "images") {
        this.showPackageImages = true;
        this.loadImages(entity);
      }
      console.log(index);
    },
    onCellEditComplete(event) {
      let { data, newValue, field } = event;
      switch (field) {
        case "actualWeightKg":
          if (this.isPositive(newValue)) {
            data[field] = newValue;
            this.service.updateField(data.id, field, newValue);
          } else {
            event.preventDefault();
          }
          break;

        default:
          if (typeof newValue == "string") {
            newValue = newValue.trim();
          }
          if (newValue) {
            data[field] = newValue;
            this.service.updateField(data.id, field, newValue);
          } else {
            event.preventDefault();
          }
          break;
      }
    },
    exportManifest() {
      let receipts = {};
      let selectedPackages = this.entities;
      selectedPackages = selectedPackages.sort(
        (a, b) => a.packageCode < b.packageCode
      );
      if (Array.isArray(this.shipment.receipts)) {
        this.shipment.receipts.forEach((receipt) => {
          receipts[receipt.receiptCode] = receipt;
        });
      }
      /*
      selectedPackages.forEach(pkg => {
        const receipt = this._addPackage(receipts[pkg.receiptCode], pkg)
        if (receipt) {
          receipts[pkg.receiptCode] = receipt
        }
      });
      */
      //Manifests
      const manifests = this._createManifests(selectedPackages, receipts);
      // Shipping form
      const shipingForm = this._createShippingForm(selectedPackages, receipts);

      var wb = utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      utils.book_append_sheet(wb, manifests, "Manifests"); // sheetAName is name of Worksheet
      utils.book_append_sheet(wb, shipingForm, "ShippingList"); // sheetAName is name of Worksheet
      // export Excel file
      const fileName = "shipment-" + this.shipment.shipmentCode + ".xlsx";
      writeFile(wb, fileName); // name of the file is 'book.xlsx'
    },
    _createManifests(selectedPackages, mapReceipts) {
      const manifests = selectedPackages.map((pkg, ind) => {
        const receipt = mapReceipts[pkg.receiptCode];
        const hdrPackageCode = this.$t("package.packageCode");
        const hdrWeightLbs = this.$t("shipment.weightLbs");
        const hdrWeightKg = this.$t("shipment.weightKg");
        const hdrSenderName = this.$t("shipment.senderName");
        const hdrSenderAddress = this.$t("shipment.senderAddress");
        const hdrSenderPhone = this.$t("shipment.senderPhone");
        const hdrRecipientName = this.$t("shipment.recipientName");
        const hdrRecipientAddress = this.$t("shipment.recipientAddress");
        const hdrRecipientPhone = this.$t("shipment.recipientPhone");
        const hdrDescription = this.$t("shipment.description");
        return {
          No: ind + 1,
          [hdrPackageCode]: pkg.packageCode,
          ["Pcs"]: 1,
          [hdrWeightLbs]: receipt.weightLbs,
          [hdrWeightKg]: converter.tokg(pkg.weightLbs),
          [hdrSenderName]: receipt.dSenderName || receipt.senderName,
          [hdrSenderAddress]: receipt.dSenderAddress || receipt.senderAddress,
          [hdrSenderPhone]: receipt.dSenderPhone || receipt.senderPhone,
          [hdrRecipientName]: receipt.dRecipientName || receipt.recipientName,
          [hdrRecipientAddress]:
            receipt.dRecipientAddress || receipt.recipientAddress,
          [hdrRecipientPhone]:
            receipt.dRecipientPhone || receipt.recipientPhone,
          ["Value"]: "GIFT",
          [hdrDescription]: pkg.itemDtos
            ?.map((item) => item.quantity + " " + item.name)
            .join(", "),
        };
      });
      var sheet = utils.json_to_sheet(manifests, {
        origin: "A4",
      });
      sheet["!cols"] = [
        { wch: 6 },
        { wch: 12 },
        { wch: 10 },
        { wch: 10 },
        { wch: 18 },
        { wch: 30 },
        { wch: 10 },
        { wch: 18 },
        { wch: 30 },
        { wch: 10 },
        { wch: 6 },
        { wch: 30 },
      ];

      const totalWeight = selectedPackages.reduce(
        (total, pkg) => total + pkg.weightLbs,
        0
      );
      utils.sheet_add_aoa(sheet, [[this.shipment.shipmentCode]], {
        origin: "A1",
      });
      utils.sheet_add_aoa(sheet, [[this.shipment.awbCode]], {
        origin: "B1",
      });
      utils.sheet_add_aoa(
        sheet,
        [
          [
            this.$t("shipment.number_of_packages", {
              numPackages: selectedPackages.length,
            }),
          ],
        ],
        { origin: "A2" }
      );
      utils.sheet_add_aoa(
        sheet,
        [[this.$t("shipment.totalWeight", { weight: totalWeight })]],
        { origin: "D2" }
      );
      return sheet;
    },
    _createShippingForm(selectedPackages, mapReceipts) {
      const hdrIndex = this.$t("package.noOfPCs");
      const hdrReceiptNumber = this.$t("shipment.receiptNumber");
      const hdrPackingNumber = this.$t("shipment.packingNumber");
      const hdrShipmentNumber = this.$t("shipment.shipmentNumber");
      const hdrWeightLbs = this.$t("shipment.weightLbs");
      const hdrWeightKg = this.$t("shipment.weightKg");
      const hdrRecipientName = this.$t("shipment.recipientName");
      const hdrRecipientAddress = this.$t("shipment.recipientAddress");
      //const hdrRecipientDistrict = this.$t('shipment.recipientDistrict');
      //const hdrRecipientCity = this.$t('shipment.recipientCity');
      const hdrRecipientPhone = this.$t("shipment.recipientPhone");
      const hdrDescription = this.$t("shipment.description");
      const shippingForm = selectedPackages.map((pkg, ind) => {
        const receipt = mapReceipts[pkg.receiptCode];
        return {
          [hdrIndex]: ind + 1,
          [hdrReceiptNumber]: pkg.receiptCode,
          [hdrPackingNumber]: pkg.packageCode,
          [hdrShipmentNumber]: this.shipment.shipmentCode,
          [hdrWeightLbs]: pkg.weightLbs,
          [hdrWeightKg]: converter.tokg(pkg.weightLbs),
          [hdrRecipientName]: receipt.dRecipientName || receipt.recipientName,
          [hdrRecipientAddress]:
            receipt.dRecipientAddress ||
            [
              receipt.recipientAddress,
              receipt.receiptDistrict,
              receipt.receiptProvince,
            ].join(","),
          [hdrRecipientPhone]:
            receipt.dRecipientPhone || receipt.recipientPhone,
          [hdrDescription]: pkg.itemDtos
            ?.map((item) => item.quantity + " " + item.name)
            .join(", "),
        };
      });
      var sheet = utils.json_to_sheet(shippingForm, {
        origin: "A4",
      });
      sheet["!cols"] = [
        { wch: 6 },
        { wch: 10 },
        { wch: 12 },
        { wch: 8 },
        { wch: 6 },
        { wch: 6 },
        { wch: 18 },
        { wch: 30 },
        { wch: 10 },
        { wch: 30 },
      ];
      const totalWeight = selectedPackages.reduce(
        (total, pkg) => total + pkg.weightLbs,
        0
      );
      utils.sheet_add_aoa(sheet, [[this.shipment.shipmentCode]], {
        origin: "A1",
      });
      utils.sheet_add_aoa(sheet, [[this.shipment.awbCode]], {
        origin: "B1",
      });
      utils.sheet_add_aoa(
        sheet,
        [
          [
            this.$t("shipment.number_of_packages", {
              numPackages: selectedPackages.length,
            }),
          ],
        ],
        { origin: "A2" }
      );
      utils.sheet_add_aoa(
        sheet,
        [[this.$t("shipment.totalWeight", { weight: totalWeight })]],
        { origin: "D2" }
      );
      return sheet;
    },
    loadImages(receiptPkg) {
      if (receiptPkg) {
        ImageService.loadImages(receiptPkg).then((response) => {
          //const baseUrl = Utils.getImageBase();
          if (Array.isArray(response)) {
            this.images = response;
          }
        });
      }
    },
    isPositive(val) {
      let str = String(val);
      str = str.trim();
      if (!str) {
        return false;
      }
      str = str.replace(/^0+/, "") || "0";
      var n = Math.floor(Number(str));
      return n !== Infinity && String(n) === str && n >= 0;
    },
  },
};
</script>
<style>
.images {
  min-width: 600px;
}
</style>
